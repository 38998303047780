//
// Video Player
// --------------------------------------------------

// Player wrapp
.video-js {
  background-color: transparent;
  position: relative;
  padding-bottom: 47px;
  font-size: 0;
  vertical-align: middle;
  overflow: hidden;
  backface-visibility: hidden;
  border-top-radius: @vplayer-border-radius;
  width: 100% !important;
  height: auto !important;

  // <video>
  .vjs-tech {
    height: 100%;
    width: 100%;
    display: block;
  }
  &::-moz-full-screen {
    position: absolute;
  }
  &::-webkit-full-screen {
    width: 100% !important;
    height: 100% !important;
  }
}

// Fullscreen view
.vjs-fullscreen {
  position: fixed;
  overflow: hidden;
  z-index: @vplayer-fullscreen-zindex;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  border-top-radius: 0;

  .vjs-control-bar {
    margin-top: 0;
    .border-bottom-radius(0);
  }
  .vjs-tech {
    background-color: @vplayer-fullscreen-bg;
  }
}

// Poster
.vjs-poster {
  margin: 0 auto;
  padding: 0;
  cursor: pointer;
  position: relative;
  width: 100%;
  max-height: 100%;
  border-top-radius: @vplayer-border-radius;
}

// Control bar
.vjs-control-bar {
  position: relative;
  height: 47px;
  color: @vplayer-control-bar-color;
  background: @vplayer-control-bar-bg;
  margin-top: -1px;
  .border-bottom-radius(@vplayer-border-radius);
  user-select: none;

  &.vjs-fade-out {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

// Other elements
.vjs-text-track-display {
  text-align: center;
  position: absolute;
  bottom: 4em;
  left: 1em;
  right: 1em;
  font-family: @font-family-base;
}
.vjs-text-track {
  display: none;
  color: @vplayer-control-bar-color;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: .1em;
  background-color: @vplayer-text-track-bg;
}
.vjs-subtitles {
  color: @inverse;
}
.vjs-captions {
  color: #fc6;
}
.vjs-tt-cue {
  display: block;
}

.vjs-fade-in {
  visibility: visible !important;
  opacity: 1 !important;
  transition: visibility 0s linear 0s, opacity .3s linear;
}
.vjs-fade-out {
  visibility: hidden !important;
  opacity: 0 !important;
  transition: visibility 0s linear 1.5s, opacity 1.5s linear;
}

// Player control general style
// --------------------------------------------------

.vjs-control {
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  display: inline-block;
  height: 18px;
  width: 18px;
  vertical-align: middle;

  &:focus {
    outline: 0
  }
  > div {
    background-position: center;
    background-repeat: no-repeat;
  }
}

// Player controls
// --------------------------------------------------

.vjs-control-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Play control
.vjs-play-control {
  cursor: pointer;
  height: 47px;
  width: 58px;

  > div {
    position: relative;
    height: 47px;

    &:before,
    &:after {
      position: absolute;
      font-family: "Flat-UI-Pro-Icons";
      color: @vplaver-play-control-color;
      font-size: floor((@component-font-size-base * 1.067)); // 16px
      top: 38%;
      left: 50%;
      margin: -0.5em 0 0 -0.5em;
      -webkit-font-smoothing: antialiased;
      transition: color .25s, opacity .25s;
    }
    &:after {
      content: "\e615";
    }
    &:before {
      content: "\e616";
    }
  }

  .vjs-paused & {
    &:hover {
      > div:before {
        color: @vplaver-play-control-hover-color;
      }
    }
    > div {
      &:after {
        .opacity(0);
      }
      &:before {
        opacity: 1;
        filter: none;
      }
    }
  }
  .vjs-playing & {
    &:hover {
      > div:after {
        color: @vplaver-play-control-hover-color;
      }
    }
    > div {
      &:after {
        opacity: 1;
        filter: none;
      }
      &:before {
        .opacity(0);
      }
    }
  }
}

// Rewind control
.vjs-rewind-control {
  width: 5em;
  cursor: pointer !important;

  > div {
    width: 19px;
    height: 16px;
    background: none transparent;
    margin: .5em auto 0;
  }
}

// Mute control
.vjs-mute-control {
  float: right;
  margin: 14px 0;
  cursor: pointer !important;

  &:hover,
  &:focus {
    > div {
      color: @vplaver-second-controls-hover-color;
    }
  }

  > div {
    height: 18px;
    color: @vplaver-second-controls-color;

    &:after,
    &:before {
      font-family: "Flat-UI-Pro-Icons";
      font-size: floor((@component-font-size-base * 1.067)); // 16px
      line-height: 18px;
      position: absolute;
      left: 50%;
      margin: 0 0 0 -0.5em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: color .25s, opacity .25s;
    }
    &:after {
      content: "\e617";
    }
    &:before {
      content: "\e618";
      .opacity(0);
    }
  }

  // Muted state
  &.vjs-vol-0 {
    > div {
      &:after {
        .opacity(0);
      }
      &:before {
        opacity: 1;
        filter: none;
      }
    }
  }
}

// Volume control
.vjs-volume-control,
.vjs-volume-level,
.vjs-volume-handle,
.vjs-volume-bar {
  display: none;
}

// Progress control
.vjs-progress-control {
  height: 12px;
  position: absolute;
  left: 60px;
  right: 160px;
  width: auto;
  top: 18px;
  background: @vplaver-progress-bg;
  border-radius: 32px;
}

.vjs-progress-holder {
  position: relative;
  cursor: pointer !important;
  padding: 0;
  margin: 0;
  height: 12px;
}

.vjs-play-progress,
.vjs-load-progress {
  display: block;
  height: 12px;
  margin: 0;
  padding: 0;
  border-radius: 32px;
}

.vjs-play-progress {
  background: @vplaver-play-progress-bg;
  left: -1px;
  position: absolute;
  top: 0;
  .border-right-radius(0);
}

.vjs-load-progress {
  background: @vplaver-load-progress-bg;

  &[style*="100%"],
  &[style*="99%"] {
    border-radius: 32px;
  }
}

.vjs-seek-handle {
  background-color: @vplayer-seek-handle-bg;
  width: 18px;
  height: 18px;
  top: 0;
  position: absolute;
  margin: -3px 0 0 -3px;
  border-radius: 50%;
  transition: background-color .25s;

  &[style*="95."] {
    margin-left: 3px;
  }
  &[style="left: 0%;"] {
    margin-left: -2px;
  }
  &:hover,
  &:focus {
    background-color: @vplayer-seek-handle-hover-bg;
  }
  &:active {
    background-color: @vplayer-seek-handle-active-bg;
  }
}


// Time control
.vjs-time-controls {
  font-family: @font-family-base;
  font-weight: 300;
  font-size: floor((@component-font-size-base * 0.867)); // 13px
  line-height: normal;
  width: auto;
  height: auto;
  position: absolute;
}

.vjs-time-divider {
  color: @vplayer-time-divider-color;
  font-size: ceil((@component-font-size-base * 0.933)); // 14px;
  position: absolute;
  right: 114px;
  top: 11px;
}

.vjs-remaining-time {
  display: none;
}

.vjs-current-time {
  right: 122px;
  top: 16px;
}

.vjs-duration {
  color: @vplayer-duration-color;
  right: 85px;
  top: 16px;
}

// Fullscreen control
.vjs-fullscreen-control {
  cursor: pointer;
  float: right;
  margin: 14px 15px;

  &:hover,
  &:focus {
    > div {
      color: @vplaver-second-controls-hover-color;
    }
  }
  > div {
    height: 18px;
    color: @vplaver-second-controls-color;

    &:before {
      font-family: "Flat-UI-Pro-Icons";
      content: "\e619";
      font-size: floor((@component-font-size-base * 1.067)); // 16px
      line-height: 18px;
      position: absolute;
      left: 50%;
      margin: 0 0 0 -0.5em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: color .25s, opacity .25s;
    }
  }
}

// Subtitles menu. Hide for no need by design.
.vjs-menu-button {
  display: none !important;
}

// Rreloader
// --------------------------------------------------

.vjs-loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ebedee;
  display: none;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  margin: -8px 0 0 -8px;
  animation: sharp 2s ease infinite;
}

.sharp-keyframes() {
  0% {
    background-color: @vplayer-preloader-primary-bg;
    border-radius: 10px;
    transform: rotate(0deg);
  }
  50% {
    background-color: @vplayer-preloader-secondary-bg;
    border-radius: 0;
    transform: rotate(180deg);
  }
  100% {
    background-color: @vplayer-preloader-primary-bg;
    border-radius: 10px;
    transform: rotate(360deg);
  }
}

@-webkit-keyframes sharp {
  .sharp-keyframes();
}

@-moz-keyframes sharp {
  .sharp-keyframes();
}

@-o-keyframes sharp {
  .sharp-keyframes();
}

@keyframes sharp {
  .sharp-keyframes();
}
