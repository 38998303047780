//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------
.btn {
  border: none;
  font-size: @btn-font-size-base;
  font-weight: @btn-font-weight;
  line-height: @btn-line-height-base;
  border-radius: @border-radius-base;
  padding: 10px 15px;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: border .25s linear, color .25s linear, background-color .25s linear;


  &:hover,
  &:focus {
    outline: none;
    color: @btn-default-color;
  }

  &:active,
  &.active {
    outline: none;
    box-shadow: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background-color: @btn-link-disabled-color;
    color: fade(@btn-default-color, 75%);
    opacity: .7;
  }

  // Button icons
  > [class^="fui-"] {
    margin: 0 1px;
    position: relative;
    line-height: 1;
    top: 1px;

    .btn-xs& {
      font-size: 11px;
      top: 0;
    }
    .btn-hg& {
      top: 2px;
    }
  }
}

// Alternate buttons
// --------------------------------------------------
.btn-default {
  .button-variant(@btn-default-color, @btn-default-bg, @btn-hover-bg, @btn-active-bg);
}
.btn-primary {
  .button-variant(@btn-default-color, @brand-secondary, @btn-primary-hover-bg, @btn-primary-active-bg);
}
.btn-info    {
  .button-variant(@btn-default-color, @brand-info, @btn-info-hover-bg, @btn-info-active-bg);
}
.btn-danger  {
  .button-variant(@btn-default-color, @brand-danger, @btn-danger-hover-bg, @btn-danger-active-bg);
}
.btn-success {
  .button-variant(@btn-default-color, @brand-success, @btn-success-hover-bg, @btn-success-active-bg);
}
.btn-warning {
  .button-variant(@btn-default-color, @brand-warning, @btn-warning-hover-bg, @btn-warning-active-bg);
}
.btn-inverse {
  .button-variant(@btn-default-color, @brand-primary, @btn-inverse-hover-bg, @btn-inverse-active-bg);
}
.btn-embossed {
  box-shadow: inset 0 -2px 0 fade(black, 15%);

  &.active,
  &:active {
    box-shadow: inset 0 2px 0 fade(black, 15%);
  }
}
.btn-wide {
  min-width: 140px;
  padding-left: 30px;
  padding-right: 30px;
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @link-color;

  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: underline;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}


// Button sizes
// --------------------------------------------------

.btn-hg {
  .button-size(13px, 20px, @btn-font-size-hg, @btn-line-height-hg, @border-radius-large);
}
.btn-lg {
  .button-size(10px, 19px, @btn-font-size-lg, @btn-line-height-lg, @border-radius-large);
}
.btn-sm {
  .button-size(9px, 13px, @btn-font-size-sm, @btn-line-height-sm, @border-radius-base);
}
.btn-xs {
  .button-size(6px, 9px, @btn-font-size-xs, @btn-line-height-xs, @border-radius-small);
}


// Button tip
.btn-tip {
  font-weight: 300;
  padding-left: 10px;
  font-size: 92%;
}

// Block button
// --------------------------------------------------

.btn-block {
  white-space: normal;
}
